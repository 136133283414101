/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/vanilla-lazyload@17.1.0/dist/lazyload.min.js
 * - /npm/jquery@3.4.1/dist/jquery.min.js
 * - /npm/bootstrap@4.4.1/dist/js/bootstrap.bundle.min.js
 * - /npm/@fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.js
 * - /npm/jquery-ui-dist@1.12.1/jquery-ui.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
